.job-description-container {
  margin: 40px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
  font-family: "Titillium Web", sans-serif;
font-weight: 600;
}

.job-description-container2 {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  font-family: "Titillium Web", sans-serif;
font-weight: 600;
}

.job-description-main {
  min-height: calc(100vh - 180px);
  padding: 50px;
  padding-top: 0;
}

.options-popup-dropdown {
width: 100%;
padding: 10px;
background-color: #f5f3f0;
font-size: 16px;
border: 0px solid #555;
border-radius: 5px;
font-family: "Titillium Web", sans-serif;
}

.job-description-main h2 {
  font-family: "Titillium Web", sans-serif;
font-weight: 500;
font-size: 1.5rem;
text-align: center;
}

.job-form label, .job-form2 label {
  display: block;
  margin-top: 10px;
  font-weight: 500;
}

.job-form input,
.job-form textarea, .job-form2 input,
.job-form2 textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f5f3f0;
  font-family: "Titillium Web", sans-serif;
}

.job-form textarea, .job-form2 textarea {
  min-height: 125px;
  font-family: "Titillium Web", sans-serif;
  font-size: 1rem;
}

.save-btn {
  font-weight: 600;
  line-height: 125%;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 1.44px;
  background: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
  background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
  color: #f5f3f0;
  padding: 0.93125rem 2.175rem;
  border: 1px solid rgba(244, 242, 240, .1);
  box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, .3);
  border-radius: 3.75rem;
  font-family: "Titillium Web", sans-serif;
  margin-top: 30px;
}


.saved-job-descriptions {
  margin: 30px 200px;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}

.saved-job-descriptions h3 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.saved-job-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Titillium Web", sans-serif;
  margin-top: 10px;
}

.saved-job-table th, 
.saved-job-table td {
  border: 5px solid #fff; /* Adjusted to match the border style from the reference */
  padding: 10px; /* Similar padding to the reference table */
}

.saved-job-table th {
  background-color: #f4f4f4; /* Light background for headers like the reference */
  text-align: left;
  font-weight: bold; /* Emphasize header text */
}

.saved-job-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternate row coloring */
}

/* General container styling */
.jr-accordion-container {
width: 100%;
max-width: 800px;
margin: 20px auto;
border-radius: 8px;
font-family: "Titillium Web", sans-serif;
}

/* Styling for individual accordion items */
.jr-accordion-item {
background-color: #f9f9f9;
margin-bottom: 10px;
border: 0px solid #ddd;
border-radius: 5px;
overflow: hidden;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
}

/* Accordion button styling (job title) */
.jr-accordion-button {
background-color: #ffffff;
color: #333;
cursor: pointer;
padding: 15px;
width: 100%;
text-align: left;
border: none;
outline: none;
font-size: 16px;
font-weight: 500;
transition: background-color 0.3s ease;
border-bottom: 1px solid #ddd;
position: relative; /* Added to position the icons absolutely */
padding-right: 20px;
}

.jr-accordion-button:hover {
background-color: #fff;
}

/* Accordion button when active (expanded) */
.jr-accordion-button.active {
background-color: #fff;
border-bottom: 1px solid #ddd;
}

/* Accordion button with an indicator for active state */
.jr-accordion-button:after {
content: '\002B'; /* Plus symbol */
font-size: 24px;
float: right;
transition: transform 0.3s ease;
}

/* Rotate plus symbol when expanded */
.jr-accordion-button.active:after {
content: '\2212'; /* Minus symbol */
transform: rotate(180deg);
}

/* Hidden content of the accordion (job description) */
.jr-accordion-content {
max-height: 0;
padding: 0 15px;
overflow: hidden;
font-family: "Titillium Web", sans-serif;
transition: max-height 0.3s ease-out, padding 0.3s ease-out;
background-color: #fff;
}

.jr-accordion-content p {
padding: 15px 0;
margin: 0;
font-size: 16px;
color: #555;
font-family: "Titillium Web", sans-serif;
}

/* Accordion content when active */
.jr-accordion-content.show {
max-height: 500px; /* Adjust based on expected content size */
padding: 15px;
transition: max-height 0.5s ease-out, padding 0.3s ease-out;
overflow-y: auto;
}



.skills-container {
margin-bottom: 20px;
}

.skills-box {
display: flex;
flex-wrap: wrap;
}

.skill-box {
background-color: #f5f3f0;
border-radius: 8px;
padding: 5px 10px;
margin-right: 5px;
margin-bottom: 5px;
display: flex;
align-items: center;
font-family: "Titillium Web", sans-serif;
font-size: 14px;
}

.add-skill-btn {
margin-left: 10px;
padding: 0px 0px;
background-color: #fff;
color: #333;
border: none;
border-radius: 5px;
cursor: pointer;
}



.delete-skill-btn {
background: none;
border: none;
color: #333;
margin-left: 5px;
cursor: pointer;
}

/* Delete button styling */
.jr-delete-button {
background-color: #f5f3f0; /* Red background */
color: #333;
border: none;
padding: 10px;
cursor: pointer;
font-size: 14px;
float: right;
margin-right: 10px;
border-radius: 4px;
margin-top: 10px;
transition: background-color 0.3s ease;
}

/* Edit and delete icons positioned to the right */
.edit-icon, .delete-icon {
right: 100px; /* Position the icons on the right side */
color: #672024;
cursor: pointer;
transition: color 0.3s ease;
font-size: 14px;
}


.button-container {
display: flex;
justify-content: center;
margin-top: 50px;
margin-bottom: 50px;
}

.jr-logo {
margin-top: 30px;
margin-left: 50px;
width: 80px; /* Adjust as needed */
cursor: pointer;
}

/* Tabs container */
.tabs-container {
display: flex;
justify-content: center;
margin-bottom: 10px;
border-bottom: 1px solid #ccc;
}

/* Tab buttons */
.tabs-container button {
background: #fff;
border: none;
padding: 15px 40px;
margin: 10px 5px;
margin-bottom: 0;
cursor: pointer;
font-size: 16px;
color: #333;
border-radius: 0px;
border-bottom: 0px solid #672024;
transition: background-color 0.3s ease, color 0.3s ease;
}

/* Active tab button */
.tabs-container button.active-tab {
background: #fff;
border-bottom: 2px solid #672024;
color: #333;
}

/* Active tab button */
.tabs-container button.active-tab p {
color: #777;
}

/* Active tab button */
.tabs-container button.active-tab p span {
color: #672024;
}

/* Hover effect */
.tabs-container button:hover {
background: #fff; /* Primary blue color */
border-bottom: 2px solid #672024;
color: #333;
}

.create-role-text {
margin-top: 5px;
font-size: 10px;
color: #fff;
text-align: center;
margin-bottom: -5px;
}

.create-role-text span {
font-size: 10px;
z-index: 3;
color: #fff;
}

.create-role-btn {
color: white;
  padding: 0;
  text-decoration: underline;
  font-family: "Titillium Web", sans-serif;
  color: #672024;
  font-weight: 600;
  background: transparent;
  border: 1px solid rgba(244, 242, 240, .1);
  background-color: var(--btn-bg-color);
  border-radius: 3.75rem;
  font-size: 0.9rem;
  cursor: pointer;
  margin-top: -10px;
}

.edit-popup-overlay {
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(0, 0, 0, 0.5);
display: flex;
align-items: center;
justify-content: center;
z-index: 1000;
}

.edit-popup {
background: #ffffff;
padding: 40px;
width: 1000px;
max-width: 90%;
max-height: 90%;
overflow-y: auto;
border-radius: 8px;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
position: relative;
z-index: 1010;
font-family: "Titillium Web", sans-serif;
}

.edit-popup h3 {
font-size: 1.2rem;
margin-bottom: 10px;
color: #333;
font-weight: 600;
font-family: "Titillium Web", sans-serif;
}

.edit-popup textarea {
width: 100%;
height: 100px;
padding: 10px;
font-size: 16px;
border: 1px solid #ccc;
border-radius: 5px;
resize: none;
font-family: "Titillium Web", sans-serif;
}


.close-btn {
position: absolute;
top: 10px;
right: 10px;
background: none;
border: none;
font-size: 20px;
cursor: pointer;
color: #666;
}

.save-btn {
margin-top: 15px;
color: #fff;
border: none;
padding: 10px 20px;
border-radius: 20px;
font-family: "Titillium Web", sans-serif;
cursor: pointer;
font-size: 16px;
transition: background-color 0.3s;
}

.jd-button-container {
position: absolute;
bottom: -20px;
right: 40px;
}

.jd-button-container2 {
position: absolute;
bottom: 0px;
right: 40px;
}

.jr-cancel-button {
font-weight: 600;
  line-height: 125%;
  text-align: center;
  font-size: 1.1rem;
  letter-spacing: 1.44px;
  background: -webkit-linear-gradient(top, #f9f8f7 0%, #ddd7d2 100%);
  background: linear-gradient(180deg, #f9f8f7 0%, #ddd7d2 100%);
  color: #672024;
  padding: 0.93125rem 2.175rem;
  border: 1px solid rgba(244, 242, 240, .1);
  border-radius: 3.75rem;
  font-family: "Titillium Web", sans-serif;
  cursor: pointer;
  margin-right: 15px;
}

/* Default styling */
.jd-form-column {
width: 48%; /* Desktop width */
display: flex;
flex-direction: column;
}

.job-form2 {
padding: 20px;
padding-top: 0;
background-color: #fff;
border-radius: 10px;
display: flex;
justify-content: space-between;
margin: 0 auto;
font-family: "Titillium Web", sans-serif;
font-weight: 600;
padding-bottom: 50px;
}

/* Mobile adjustments */
@media (max-width: 768px) {
.jd-form-column {
  width: 100%; /* Full width on mobile */
  margin-bottom: 15px; /* Add spacing between stacked items */
}

.job-form2 {
  flex-direction: column; /* Stack items vertically */
  padding: 15px; /* Adjust padding for mobile */
  justify-content: center; /* Center align content */
  margin: 0;
}

.job-description-main {
  margin: 10px;
  padding: 10px;
}

.create-role-text {
  display: none;
}

.jd-button-container3 {
  flex-direction: column;
  bottom: -50px;
  right: 40px;
  justify-content: center;
  width: 100%;
}
}


.stepper-header {
font-family: "Titillium Web", sans-serif;
font-weight: 600;
padding: 12px 20px;
}

.stepper-header p {
font-size: 0.8rem;
}

.step-progress-bar {
font-family: "Titillium Web", sans-serif;
font-weight: 500;
font-size: 1.1rem;
color: #fff;
background: linear-gradient(197deg, #030507 12.05%, #113e3c 88.83%);
padding: 10px;
position: relative;
width: 100%;
}

.progress-line {
position: relative;
height: 3px;
background-color: rgba(255, 255, 255, 0);
border-radius: 3px;
overflow: hidden;
display: flex;
}

.step-progress-fill {
height: 100%;
transition: width 0.3s ease;
border-radius: 3px;
margin-right: 10px;
}


/* Default styling */
.jd-button-container {
position: absolute;
bottom: 20px;
right: 40px;
}

.jd-button-container2 {
position: absolute;
bottom: -50px;
right: 40px;
}

.jr-cancel-button,
.proceed-button {
font-weight: 600;
line-height: 125%;
text-align: center;
font-size: 1.1rem;
letter-spacing: 1.44px;
padding: 0.93125rem 2.175rem;
border: 1px solid rgba(244, 242, 240, 0.1);
border-radius: 3.75rem;
font-family: "Titillium Web", sans-serif;
cursor: pointer;
}

.jr-cancel-button {
background: linear-gradient(180deg, #f9f8f7 0%, #ddd7d2 100%);
color: #672024;
margin-right: 15px;
}

.proceed-button {
background: linear-gradient(17deg, #030507 12.05%, #113e3c 88.83%);
color: #f5f3f0;
box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.3);
z-index: 2;
}

/* Mobile adjustments */
@media (max-width: 768px) {
.jd-button-container,
.jd-button-container2,
.jd-button-container3 {
  position: static; /* Reset position to default */
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center align */
  margin-top: 20px; /* Add spacing above buttons */
}

.jr-cancel-button {
  width: 80%; /* Set button width to fit mobile screen */
  margin: 10px 0; /* Add spacing between buttons */
}

.step-progress-bar {
  font-size: 0.9rem;
}
}



.job-list {
max-height: 200px;
overflow-y: auto;
border: 1px solid #e2e8f0;
border-top: none;
border-radius: 0 0 0.375rem 0.375rem;
background-color: #fff;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.job-list li {
padding: 0.5rem 1rem;
cursor: pointer;
transition: background-color 0.15s ease-in-out;
font-weight: 300;
}

.job-list li:hover {
background-color: #f3f4f6;
}

.job-list li.selected {
background-color: #e5e7eb;
font-weight: 600;
}

.job-description-container {
position: relative;
width: 100%;
max-width: 400px;
margin: 0 auto;
}

.search-dropdown {
position: absolute;
width: 100%;
z-index: 10;
}



/* Search icon styling */
.search-icon {
color: #d4d4d4;
font-size: 20px;
margin-right: 8px;
margin-top: 20px;
margin-left: 8px;
}


.job-description-header1 {
display: flex;
justify-content: space-between;
align-items: flex-start;
margin-bottom: 20px;
font-family: "Titillium Web", sans-serif;
margin-top: 20px;
font-weight: 600;
}

@media (max-width: 768px) { 
.job-description-header1{
  display: flex;
  justify-content: left;
}
}

.job-role-heading1 {
font-size: 0.9rem;
font-weight: bold;
color: #333;
margin-bottom: 15px;
text-align: left;
width: 100%;
}

.job-description-header1 h3 {
font-weight: 600;
}

.job-description-header1 p {
font-size: 0.9rem;
font-weight: 500;
}

.create-new-button {
background: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
  color: #FFF;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-family: "Titillium Web", sans-serif;
}

.create-new-button:hover {
background-color: #8b2b30;
}

.action-dropdown {
position: relative;
}

.action-icon {
cursor: pointer;
}

.delete-confirm {
display: flex;
flex-direction: column;
justify-content: center;
position: absolute;
right: 0;
background-color: white;
border: 1px solid #ccc;
padding: 10px;
border-radius: 5px;
z-index: 10;
width: 200px;
text-align: center;
}

.delete-confirm button {
margin-right: 10px;
padding: 5px 10px;
border: none;
border-radius: 3px;
cursor: pointer;
text-align: center;
margin-top: 10px;
}

.delete-confirm button:last-child {
background-color: #f0f0f0;
}

.historyPage-table input[type="checkbox"] {
  width: 12px;        /* Increase the size */
  height: 12px;       /* Increase the size */
  cursor: pointer;    /* Pointer cursor for better UX */
  accent-color: #672024;  /* Change color when checked */
  transform: scale(1.5);  /* Slightly enlarge for better visibility */
}

.historyPage-table input[type="checkbox"]:checked {
  background-color: #672024; /* Custom color for checked state */
  border-color: #672024;     /* Border color change on check */
}