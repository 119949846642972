

/* Hero Section */
.hero-section-unique {
  position: relative;
  color: #111;
  text-align: left;
  padding: 0px 20px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  margin: 100px 150px;
  z-index: 0;
}

.hero-section-unique::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./bg-3.png');
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
  z-index: -1; /* Place it behind the content */
}

  
  .hero-content-unique {
    max-width: 45.5%;
    margin: 0 ;
    z-index: 2;
  }
  
  .tagline-unique {
    font-size: 0.7rem;
    margin-bottom: 20px;
    font-weight: 400;
    background: linear-gradient(180deg, rgba(103, 32, 36, 0.1) 0%, rgba(103, 32, 36, 0) 100%);
    color: #5b161a;
    width: fit-content;
    text-transform: uppercase;
    border-radius: 4.25rem;
    line-height: 150%;
    letter-spacing: 1.5px;
    padding: 10px 15px;
    z-index: 2;
  }
  
  .headline-unique {
    font-size: 3.6rem;
    margin-bottom: 20px;
    font-weight: 500;
    line-height: 1.2;
  }
  
  .subheading-unique {
    font-size: 1.15rem;
    margin-bottom: 50px;
    font-weight: 500;
    max-width: 85%;
    color: #555;
  }

  .cta-button-homepage {
    font-weight: 600;
    line-height: 125%;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 1.44px;
    background: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
    background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
    color: #f5f3f0;
    padding: 1.13125rem 3.175rem;
    border: 1px solid rgba(244, 242, 240, .1);
    box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, .3);
    border-radius: 3.75rem;
    font-family: "Titillium Web", sans-serif;
    margin-top: 0px;
    cursor: pointer;
  }
  
  /* Problem Statement */
  .problem-statement-unique {
    padding: 100px 20px;
    text-align: center;
    font-family: "Titillium Web", sans-serif;
    font-weight: 500;
    color: #f1f1f1;
    background: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
    background: linear-gradient(17deg, #030507 12.05%, #11293e 88.83%);
  }
  
  .section-title-unique {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: 500;
    font-family: "Titillium Web", sans-serif;
  }
  
  .section-text-unique {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.8;
  }
  
  /* How it Works */
  .how-it-works-unique {
    position: relative;
    color: #111;
    text-align: left;
    padding: 10px 20px;
    padding-bottom: 50px;
    font-family: "Titillium Web", sans-serif;
    font-weight: 500;
    margin: 50px 150px;
    background-color: #fff;
    z-index: 2;
    background-size: cover;
    background-position: top;
    transition: background-image 0.5s ease-in-out;
  }
  
  .how-it-works-unique::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    
  }
  
  .how-it-works-title {
    margin-top: 50px;
    margin-left: 170px;
    max-width: 30%;
    margin-bottom: 0px;
  }
  .section-title-hiw-unique {
    font-size: 2.7rem;
    margin-bottom: 0px;
    font-weight: 500;
    font-family: "Titillium Web", sans-serif;
    margin-top: 0px;
    line-height: 120%;
  }
  .steps-container-unique {
    max-width: 35.5%;
    margin: 0 0px;
    z-index: 2;
    border-top: 1px solid #ccc;
  }
  .step-unique {
    background-color: rgba(255, 255, 255, 0.9);
    margin-bottom: 0px;
    border-radius: 0px;
    padding: 20px 40px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-bottom: 1px solid #ccc;
  }
  
  .step-unique:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  
  .step-unique.active {
    border-left: 5px solid #5b161a;
  }

  .step-unique.active h3 {
    color: #5b1619de;
  }
  
  .step-unique h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    font-weight: 400;
    color: #555;
    font-family: "Titillium Web", sans-serif;
  }
  
  .step-unique p {
    font-size: 0.9rem;
    line-height: 1.6;
    color: #555;
    font-weight: 500;
    font-family: "Titillium Web", sans-serif;
    margin-top: 10px;
  }
  

  /* Benefits Section */
  .benefits-section-unique {
    background: #fff;
    padding: 100px 20px;
    padding-top: 0;
    text-align: center;
    color: #333;
  }
  
  .benefits-cards-unique {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0px 150px;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  
  .benefit-card-unique {
    color: #333;
    font-weight: 600;
    background: transparent;
    padding: 40px 25px;
    width: 22%;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 0px;
    font-weight: 500;
    font-family: "Titillium Web", sans-serif;
    text-align: left;
    background-color: #f5f3f0;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .benefit-card-unique img {
    width: 60px;
    height: 60px;
    margin-bottom: 30px;
  }
  
  .benefit-card-unique h3 {
    font-size: 1.7rem;
    color: #5b161a;
    font-weight: 500;
    font-family: "Titillium Web", sans-serif;
    text-transform: uppercase;
    line-height: 120%;
  }

  .benefit-card-unique p {
    margin-top: 25px;
    font-size: 0.9rem;
    font-family: "Titillium Web", sans-serif;
    font-weight: 500;
  }
  
  /* Features Section */
  .features-section-unique {
    padding: 80px 20px;
    text-align: center;
  }
  
  .features-cards-unique {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature-card-unique {
    background-color: white;
    padding: 20px;
    width: 220px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .feature-card-unique h3 {
    font-size: 1.2rem;
  }
  
  .benefits-text-unique {
    margin-top: 40px;
    font-size: 1.1rem;
  }
  
  /* Final CTA Section */
  .final-cta-section-unique {
    color: white;
    background: 
  repeating-linear-gradient(161deg, transparent, transparent 10px, rgba(255, 255, 255, 0.05) 10px, rgba(255, 255, 255, 0.05) 11px), 
  repeating-linear-gradient(13deg, transparent, transparent 10px, rgba(255, 255, 255, 0.05) 10px, rgba(255, 255, 255, 0.05) 11px), 
  linear-gradient(45deg, #030507 12.05%, #11293e 88.83%);

    padding: 100px 20px;
    text-align: center;
    font-weight: 500;
    font-family: "Titillium Web", sans-serif;
  }
  
  .final-cta-section-unique h2 {
    font-size: 2.7rem;
    margin-bottom: 0px;
    font-weight: 500;
    line-height: 120%;
    font-family: "Titillium Web", sans-serif;
  }

  .cta-tagline-unique {
    margin-bottom: 40px;
    font-weight: 400;
    background: linear-gradient(16.82deg, #15293B 12.05%, #253747 88.83%);
border: 0.5px solid rgba(103, 32, 36, 0.1);
border-radius: 68px;
    color: #ffffff;
    width: fit-content;
    text-transform: uppercase;
    line-height: 150%;
    letter-spacing: 1.5px;
    padding: 10px 15px;
    z-index: 2;
    font-size: 0.7rem;
  }
  
  .final-cta-section-unique p {
    font-size: 0.9rem;
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: 400;
  }
  
  .final-cta-section-unique .cta-button-unique {
    font-weight: 600;
    line-height: 125%;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 1.44px;
    background: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
    background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
    color: #f5f3f0;
    padding: 0.93125rem 3.175rem;
    border: 1px solid rgba(244, 242, 240, .1);
    box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, .3);
    border-radius: 3.75rem;
    font-family: "Titillium Web", sans-serif;
    margin-top: 10px;
    text-transform: uppercase;
    cursor: pointer;
  }

  .contact-form-cta {
    font-weight: 600;
    line-height: 125%;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 1.44px;
    background: -webkit-linear-gradient(top, #f9f8f7 0%, #ddd7d2 100%);
    background: linear-gradient(180deg, #f9f8f7 0%, #ddd7d2 100%);
    color: #5b161a;
    padding: 0.93125rem 3.175rem;
    border: 1px solid rgba(244, 242, 240, .1);
    box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, .3);
    border-radius: 3.75rem;
    font-family: "Titillium Web", sans-serif;
    margin-top: 10px;
    margin-left: 20px;
    text-transform: uppercase;
    cursor: pointer;
  }

  /* Mobile Optimized CSS */
@media (max-width: 768px) {
  /* Remove background image on mobile screens */
  .hero-section-unique {
    background: none;
    padding: 50px 20px;
  }

  /* Make text and layout responsive for mobile */
  .hero-content-unique {
    max-width: 100%;
    margin: 0 20px;
  }

  .headline-unique {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .subheading-unique {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }

  .cta-button-homepage {
    font-size: 0.9rem;
    padding: 0.75rem 1.75rem;
  }

  /* Adjust problem-statement section */
  .problem-statement-unique {
    padding: 60px 20px;
  }

  .section-title-unique {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .section-text-unique {
    font-size: 1rem;
    line-height: 1.5;
  }

  /* How it Works section on mobile */
  .steps-container-unique {
    gap: 60px;
  }

  .step-unique {
    flex-direction: column;
    text-align: center;
  }

  .step-image-unique {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }

  .step-text-unique {
    max-width: 100%;
    padding: 0 10px;
  }

  .step-text-unique h3 {
    font-size: 1.5rem;
  }

  .step-text-unique p {
    font-size: 1rem;
  }

  /* Benefits section adjustments */
  .benefits-cards-unique {
    flex-direction: column;
    margin-top: 20px;
  }

  .benefit-card-unique {
    width: 100%;
    margin: 10px 0;
  }

  /* Adjust final call to action */
  .final-cta-section-unique {
    padding: 40px 20px;
  }

  .cta-button-unique {
    font-size: 1rem;
    padding: 0.75rem 2rem;
  }
}

  
  /* Mobile Optimized CSS */
  @media (max-width: 768px) {
    /* Remove background image on mobile screens */
    .hero-section-unique {
      background: none;
      padding: 50px 20px;
    }
  
    /* Make text and layout responsive for mobile */
    .hero-content-unique {
      max-width: 100%;
      margin: 0 20px;
    }
  
    .headline-unique {
      font-size: 2rem;
      margin-bottom: 15px;
    }
  
    .subheading-unique {
      font-size: 1.1rem;
      margin-bottom: 20px;
    }
  
    .cta-button-homepage {
      font-size: 0.9rem;
      padding: 0.75rem 1.75rem;
    }
  
    /* Adjust problem-statement section */
    .problem-statement-unique {
      padding: 60px 20px;
    }
  
    .section-title-unique {
      font-size: 2rem;
      margin-bottom: 15px;
    }
  
    .section-text-unique {
      font-size: 1rem;
      line-height: 1.5;
    }
  
    /* How it Works section on mobile */
    .steps-container-unique {
      gap: 60px;
    }
  
    .step-unique {
      flex-direction: column;
      text-align: center;
    }
  
    .step-image-unique {
      width: 100%;
      height: auto;
      margin: 20px 0;
    }
  
    .step-text-unique {
      max-width: 100%;
      padding: 0 10px;
    }
  
    .step-text-unique h3 {
      font-size: 1.5rem;
    }
  
    .step-text-unique p {
      font-size: 1rem;
    }
  
    /* Benefits section adjustments */
    .benefits-cards-unique {
      flex-direction: column;
      margin-top: 20px;
    }
  
    .benefit-card-unique {
      width: 100%;
      margin: 10px 0;
    }
  
    /* Adjust final call to action */
    .final-cta-section-unique {
      padding: 40px 20px;
    }
  
    .cta-button-unique {
      font-size: 1rem;
      padding: 0.75rem 2rem;
    }
  
    /* Remove background in hero section for mobile */
    .hero-section-unique::before {
      background-image: none;
    }
  
    /* Adjust hero section layout */
    .hero-section-unique {
      margin: 50px 20px;
    }
  
    .hero-content-unique {
      max-width: 100%;
    }
  
    .headline-unique {
      font-size: 2.5rem;
    }
  
    .subheading-unique {
      font-size: 1rem;
      max-width: 100%;
    }
  
    /* Remove background in how it works section for mobile */
    .how-it-works-unique {
      background-image: none !important;
      margin: 20px;
    }
  
    /* Adjust how it works section layout */
    .how-it-works-title {
      margin-left: 20px;
      max-width: 100%;
    }
  
    .section-title-hiw-unique {
      font-size: 2rem;
    }
  
    .steps-container-unique {
      max-width: 100%;
    }
  
    /* Adjust benefits section for mobile */
    .benefits-cards-unique {
      padding: 0 20px;
    }
  
    .benefit-card-unique {
      width: 100%;
      margin: 10px 0;
    }
  
    /* Adjust final CTA section for mobile */
    .final-cta-section-unique h2 {
      font-size: 2rem;
    }
  }  

/* Homepage Pricing Section */
.homepage-pricing-section {
  background-color: #f7f6f5;
  padding: 100px 20px;
  text-align: center;
  font-family: "Titillium Web", sans-serif;
}

.homepage-pricing-content {
  max-width: 1200px;
  margin: 0 auto;
}

.card-title span {
  font-size: 2rem;
  font-weight: 600;
  color: #282828;
  font-family: "Titillium Web", sans-serif;
  padding-bottom: 30px;
}

.homepage-pricing-description {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 60px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.homepage-pricing-card {
  background: transparent;
  border-radius: 0px;
  padding: 30px 40px;
  max-width: 400px;
  margin: 0 auto;
  color: #333;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
}

.card-badge {
  position: absolute;
  top: 20px;
  right: -30px;
  background: #5b161a;
  color: #fff;
  padding: 8px 40px;
  font-size: 0.8rem;
  font-weight: 600;
  transform: rotate(45deg);
}

.strikethrough {
  display: block;
  text-decoration: line-through;
  color: #a0a0a0;
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.homepage-pricing-card-description {
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #333;
}

.homepage-pricing-card-price {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: #333;
}

.homepage-pricing-features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 40px;
  text-align: left;
}

.homepage-pricing-features li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 30px;
  font-size: 1.1rem;
}

.homepage-pricing-features li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #5b161a;
  font-weight: 700;
}

.homepage-pricing-cta-button {
  font-weight: 600;
    line-height: 125%;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 1.44px;
    background: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
    background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
    color: #f5f3f0;
    padding: 0.93125rem 3.175rem;
    border: 1px solid rgba(244, 242, 240, .1);
    box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, .3);
    border-radius: 3.75rem;
    font-family: "Titillium Web", sans-serif;
    margin-top: 10px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
}

.button-arrow {
  margin-left: 10px;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.homepage-pricing-cta-button:hover .button-arrow {
  transform: translateX(5px);
}

@media (max-width: 768px) {
  .homepage-pricing-title {
    font-size: 2.5rem;
  }

  .homepage-pricing-description {
    font-size: 1rem;
  }

  .homepage-pricing-card {
    padding: 40px 30px;
  }

  .card-title {
    font-size: 2rem;
  }

  .homepage-pricing-card-price {
    font-size: 2.5rem;
  }
}


.homepage-pricing-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.homepage-pricing-card {
  flex: 1;
  min-width: 250px;
  max-width: 370px;
}

@media (max-width: 768px) {
  .homepage-pricing-cards {
    flex-direction: column;
    align-items: center;
  }

  .homepage-pricing-card {
    width: 100%;
    max-width: 400px;
  }
}


.home-footer {
  height: 100px;
  background: #f7f6f5; 
  padding: 0px 150px; 
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Mobile-optimized footer styles */
@media (max-width: 768px) {
  .home-footer {
    height: auto;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-left {
    margin-bottom: 15px;
  }

  .footer-right {
    align-items: center;
  }

  .footer-right a {
    margin: 5px 0;
  }

  .footer-right div {
    display: none; /* Hide the divider */
  }
}