body {
  font-family: "Titillium Web", sans-serif;
}

.results-page-container {
  display: flex;
  min-height: calc( 100vh - 200px);
  background-color: #fff;
  font-family: "Titillium Web", sans-serif;
}

.results-page-sidebar {
  width: 250px;
  background: #fff;
  padding: 20px;
  color: #333;
  border-right: 1px solid #ccc;
}

.results-page-sidebar-title {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  line-height: 1.5rem;
}

.results-page-sidebar-entry {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;
  border-radius: 5px;
}

.results-page-sidebar-entry:hover {
  background-color: #f5f3f0;
}

.results-page-entry-date {
  font-size: 0.8em;
  color: #333;
}

.results-page-entry-text {
  font-weight: 400;
  color: #333;
}

.results-page-content {
  flex-grow: 1;
  padding: 40px;
}

.results-page-view-toggle {
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.results-page-view-toggle h3 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  background: #fff;
  border-radius: 5px;

}

.results-page-view-toggle span {
  background: #fff;
  border-radius: 5px;
  padding: 10px 0px;
}

.results-page-toggle-button {
  color: white;
    padding: 10px 15px;
    text-decoration: none;
    font-family: "Titillium Web", sans-serif;
    color: #672024;
    font-weight: 600;
    background: -webkit-linear-gradient(top, #f9f8f7 0%, #ddd7d2 100%);
    background: linear-gradient(180deg, #f9f8f7 0%, #ddd7d2 100%);
    border: 1px solid rgba(244, 242, 240, .1);
    background-color: var(--btn-bg-color);
    border-radius: 3.75rem;
}

.results-page-toggle-button:hover {
  background-color: #530c1a; /* Darker shade for hover effect */
}

.results-page-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.role-match-card {
  background: -webkit-linear-gradient(top, #f9f8f7 0%, #e7e2de 100%);
    background: linear-gradient(180deg, #f9f8f7 0%, #eee9e5 100%);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0);
  width: 270px;
}

.role-match-card h3 {
  margin: 0;
  font-size: 1rem;
  color: #333;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.progress-bar-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  font-size: 2rem;
  border-bottom: 1px solid #ddd;
}

.value-basis {
  font-size: 0.9rem;
  margin: 10px 0;
  color: #777;
  text-align: center;
}

.criteria-list {
  list-style-type: none;
  padding: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.criteria-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  text-align: left;
}

.criteria-item span {
  font-size: 0.9rem;
  color: #333;
}

.score-container {
  display: flex;
  align-items: center; /* Align items vertically */
  min-width: 38px; /* Set a minimum width to ensure alignment */
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; /* Space between dot and score */
}

.criteria-item.job-skills .dot {
  background-color: #ff5a5a; /* Red for Job Skills */
}

.criteria-item.qualifications-match .dot {
  background-color: #ffcc00; /* Yellow for Soft Skills */
}


.criteria-item.total-score .dot {
  background-color: #00cc00; /* Green for Education */
}

.download-resume-button {
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #ddd;
  color: #333;
  margin-top: 20px;
}

.results-page-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.results-page-table th {
  background-color: #fff; /* Matching header background from historyPage-table */
  color: #333; /* Keep the text color consistent */
  border-bottom: 0px solid #ccc;
  font-weight: 600;
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds "..." if text overflows */
  vertical-align: middle; /* Ensures consistent vertical alignment */
}

.results-page-table th,
.results-page-table td {
  border: none;
  padding: 20px;
  text-align: left;
  vertical-align: middle; /* Ensures consistent alignment */
}

.results-page-table tr {
  background-color: #f5f3f0;
  border-radius: 8px;
  border-bottom: 5px solid #fff;
}

.results-page-table tr:nth-child(even) {
  background-color: #f5f3f0; /* Alternating row colors */
}

.results-page-toggle-button {
  margin-right: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.results-page-toggle-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.sort-dropdown {
  margin-right: 10px;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #672024;
}

.results-table-download-button {
  background-color: #fff;
  border: none;
  cursor: pointer;
  color: #007bff;
  margin-bottom: 20px;
}

.gap-section {
  font-size: 0.9rem;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .results-page-container {
    flex-direction: column;
    padding: 10px;
  }

  .results-page-content {
    padding: 20px;
  }

  .results-page-view-toggle {
    flex-direction: column;
    gap: 10px;
  }

  .results-page-toggle-button {
    font-size: 0.9rem;
    padding: 8px 10px;
  }

  .sort-dropdown {
    font-size: 0.9rem;
    padding: 8px;
  }

  .results-page-cards-container {
    flex-direction: column;
    gap: 15px;
  }

  .table-scrollable-container {
    overflow-x: auto; /* Enable horizontal scrolling for table */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  .filter-toggle-button {
    margin: 10px 0;
  }

  .role-match-card {
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }

  .progress-bar-container {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
  }

  .criteria-list {
    text-align: left;
  }

  .results-page-table {
    width: 100%;
    font-size: 0.9rem;
  }

  .results-page-table th,
 .results-page-table td {
    padding: 8px;
  }

  .results-page-toggle-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .results-page-view-toggle h3 {
    font-size: 1.2rem;
  }

  .results-page-sidebar {
    width: 100%;
    padding: 10px;
  }

  .results-page-sidebar-title {
    font-size: 1.2rem;
  }

  .results-page-sidebar-entry {
    font-size: 1rem;
  }

  .results-page-toggle-button {
    font-size: 0.85rem;
    padding: 10px;
    text-align: center;
  }
}


/* Filter section container */
.filter-section-container {
  margin: 20px 0;
  background: #fff;
}

.filter-section {
  background-color: #fff;
}

.filter-toggle-button {
  background-color: #fff;
  color: #333;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-section {
  display: none;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding-bottom: 0;
}

.filter-section.open {
  display: block;
  animation: slideDown 0.5s ease;
  padding-top: 30px;
}

@keyframes slideDown {
  from {
      max-height: 0;
      opacity: 0;
  }
  to {
      max-height: 500px;
      opacity: 1;
  }
}

.filter-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.-filter-option {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.filter-row label {
  font-weight: 500;
  margin-right: 10px;
  color: #333;
  font-size: 14px;
}

.filter-row select, .filter-row input[type="checkbox"] {
  padding: 8px 8px;
  border: 0px solid #111;
  border-radius: 6px;
  font-size: 14px;
  background: #f5f3f0;
  margin-right: 20px;
  transition: border-color 0.3s ease;
}

.filter-row select:hover, .filter-row input[type="checkbox"]:hover {
  background: #f7f7f7;
}

.sort-dropdown {
  padding: 10px 14px;
  border: 2px solid #ccc;
  border-radius: 10px;
  transition: border-color 0.3s ease;
}

.sort-dropdown:hover {
  border-color: #672024;
}

.sort-dropdown option {
  padding: 10px;
}

/* Buttons in the filter section */
.filter-row button {
  background: #f5f3f0;
  color: #333;
  padding: 8px 8px;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 20px;
  border: 0px solid #111;
}

/* Responsiveness for filter rows */
@media (max-width: 768px) {
  .filter-row {
      flex-direction: column;
      align-items: stretch;
  }

  .filter-row label {
      margin-bottom: 10px;
  }

  .filter-row select {
      margin-bottom: 15px;
  }
}


.results-page-view-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.results-page-download-button {
  background: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
  background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
    color: #FFF;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-family: "Titillium Web", sans-serif;
}

.results-page-shortlist-button {
  background: -webkit-linear-gradient(top, #f9f8f7 0%, #ddd7d2 100%);
  background: linear-gradient(180deg, #f9f8f7 0%, #ddd7d2 100%);
    color: #672024;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    font-family: "Titillium Web", sans-serif;
    margin-right: 10px;
}

.results-page-shortlist-button :disabled {
  color: #ccc;
}

.results-page-toggle-button:hover {
    background-color: #945043;
}

.results-page-view-toggle h3 {
    margin: 0;
}

.results-page-view-toggle p {
    font-size: 20px;
    cursor: pointer;
}

.results-view-button {
  font-size: 25px;
  cursor: pointer;
}

.results-page-toggle-button {
    margin-left: auto;
}

/*.bluish-row {
  background-color: #e0f7fa; /* Light bluish background 
}*/

.greenish-row {
  border-left: 4px solid #23d3ff; /* Light greenish background */
  border-radius: 8px;
}


.red-text {
  color: red; /* Red color for gaps text */
}

.green-text {
  color: green; /* Red color for gaps text */
}

.red-triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid red;
    display: inline-block;
    margin-right: 4px;
}

/* Triangle Icons */
.red-downward-triangle::after {
  content: "▼";
  color: red;
  font-size: 12px;
  margin-left: 4px;
}

.black-text {
  color: #333;
}

.green-upward-triangle::after {
  content: "▲";
  color: green;
  font-size: 12px;
  margin-left: 4px;
}


/* Add this CSS to Results.css or in a style block */
.results-page-table input[type="checkbox"] {
  width: 12px;        /* Increase the size */
  height: 12px;       /* Increase the size */
  cursor: pointer;    /* Pointer cursor for better UX */
  accent-color: #672024;  /* Change color when checked */
  transform: scale(1.5);  /* Slightly enlarge for better visibility */
}

.results-page-table input[type="checkbox"]:checked {
  background-color: #672024; /* Custom color for checked state */
  border-color: #672024;     /* Border color change on check */
}

.save-shortlist-button {
  padding: 10px 15px;
    text-decoration: none;
    font-family: "Titillium Web", sans-serif;
    color: #672024;
    font-weight: 600;
    background: -webkit-linear-gradient(top, #f9f8f7 0%, #ddd7d2 100%);
    background: linear-gradient(180deg, #f9f8f7 0%, #ddd7d2 100%);
    border: 1px solid rgba(244, 242, 240, .1);
    background-color: var(--btn-bg-color);
    border-radius: 3.75rem;
    cursor: pointer;
    margin-top: 20px;
}

.results-progress-bar {
  width: 60%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
}

.filler {
  height: 100%;
  width: 0%;
  background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
  transition: width 0.5s ease-in-out;
}

.progress-text {
  text-align: center;
  line-height: 20px;
  color: #333;
  margin-top: 20px;
}

.yellow-border {
  border-left: 0px solid #fa0000; /* Light greenish background */
}

.select-all-button {
  padding: 0;
  background-color: #fff; /* Darker shade for visibility */
  color: #333;
  border: none;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 0px; /* Space between other buttons if needed */
  font-family: "Titillium Web", sans-serif;
}

.bulk-actions-dropdown {
  position: relative;
  display: inline-block;
}

.bulk-actions-button {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px 35px;
  cursor: pointer;
  margin-left: 20px;
}

.bulk-actions-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.bulk-actions-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1;
  margin-left: 105px;
}

.bulk-actions-menu button {
  display: block;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}

.bulk-actions-menu button:hover {
  background-color: #f0f0f0;
}

.bulk-actions-dropdown input[type="checkbox"] {
  width: 12px;        /* Increase the size */
  height: 12px;       /* Increase the size */
  cursor: pointer;    /* Pointer cursor for better UX */
  accent-color: #672024;  /* Change color when checked */
  transform: scale(1.5);  /* Slightly enlarge for better visibility */
  margin-right: 10px;
}


/* Filter section container */
.filter-section-container {
  margin: 20px 0;
  background: #fff;
}

.filter-section {
  background-color: #fff;
  padding: 0px 0px;
  padding-bottom: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0);
}

.filter-section.open {
  display: block;
  animation: slideDown 0.5s ease;
}

@keyframes slideDown {
  from { 
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 500px;
    opacity: 1;
  }
}

.filter-row h3 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
}

.filter-row p {
  font-family: "Titillium Web", sans-serif;
  font-size: 0.9rem;
}

.sort-dropdown,
.filter-toggle-button,
.search-container {
  flex: 1;
  min-width: 100px;
}

.sort-dropdown {
  padding: 10px 14px;
  border: 2px solid #ccc;
  border-radius: 10px;
  transition: border-color 0.3s ease;
}

.sort-dropdown:hover {
  border-color: #672024;
}

.filter-toggle-button {
  background-color: #fff;
  color: #333;
  padding: 10px 20px;
  font-size: 14px;
  border: 1px solid #111;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-toggle-button:hover {
  background-color: #f7f7f7;
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-input-filter {
  max-width: 150px;
  padding: 8px;
  padding-right: 40px;
  border: 0px solid #ccc;
  background: #f5f3f0;
  border-radius: 10px;
  font-size: 14px;
  transition: border-color 0.3s ease;
  font-family: "Titillium Web", sans-serif;
}

.search-input-filter:focus {
  border-color: #672024;
  outline: none;
}

.search-icon {
  position: absolute;
  right: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .filter-row {
    flex-direction: column;
    align-items: stretch;
  }

  .sort-dropdown,
  .filter-toggle-button,
  .search-container {
    width: 100%;
    margin-bottom: 10px;
  }
}

.green-text {
  color: #008000;
}

.sortable-header {
  cursor: pointer;
  user-select: none;
}

.sort-arrows {
  display: inline-flex;
  flex-direction: column;
  margin-left: 5px;
  vertical-align: middle;
  z-index: 3;
}

.sort-arrows svg {
  height: 12px;
  width: 12px;
  opacity: 0.5;
}

.sort-arrows svg.active {
  opacity: 1;
  color: #672024;
}


.verified-label {
  color: #003f02;
}

.verified-icon-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12px; /* Adjust as needed */
  height: 12px; /* Adjust as needed */
  border-radius: 50%;
  background-color: #003f02; /* Green circle */
  margin: 5px;
  margin-top: 10px;
}

/* Style for the check mark */
.verified-check-icon {
  color: white;
  font-size: 0.7em; /* Adjust size of check as needed */
}

.results-page-content2 {
  background-color: #1a4061;
  width: 100%;
}

.r-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Success message box */
.success-box {
  background-color: -webkit-linear-gradient(73deg, #030507 12.05%, #113e33 88.83%);
    background: linear-gradient(17deg, #040b09 12.05%, #113e3c 88.83%);
  color: #fff;
  padding: 60px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-width: 700px;
  text-align: center;
  width: 100%;
  font-family: "Titillium Web", sans-serif;
  font-size: 1.2rem;
}

/* Done button */
.done-button {
  color: white;
    padding: 8px 20px;
    text-decoration: none;
    font-family: "Titillium Web", sans-serif;
    color: #672024;
    font-weight: 600;
    background: -webkit-linear-gradient(top, #f9f8f7 0%, #ddd7d2 100%);
    background: linear-gradient(180deg, #f9f8f7 0%, #ddd7d2 100%);
    border: 1px solid rgba(244, 242, 240, .1);
    background-color: var(--btn-bg-color);
    border-radius: 3.75rem;
    margin-right: 20px;
    cursor: pointer;
    margin-top: 30px;
    font-size: 0.9rem;
}

.done-button:hover {
  background-color: #e68a00;
}