.password-reset-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .password-reset-header {
    padding: 20px;
    background-color: #0f253a;
  background-image: repeating-linear-gradient(161deg, transparent, transparent 10px, rgba(255, 255, 255, .05) 10px, rgba(255, 255, 255, .05) 11px), repeating-linear-gradient(13deg, transparent, transparent 10px, rgba(255, 255, 255, .05) 10px, rgba(255, 255, 255, .05) 11px);
  }
  
  .logo {
    margin-top: 10px;
  margin-left: 50px;
  width: 80px; /* Adjust as needed */
  cursor: pointer;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  }
  
  .password-reset-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .password-reset-form h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
  }
  
  .form-group {
    margin-bottom: 20px;
    position: relative;
    width: 300px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: 600;
    font-family: "Titillium Web", sans-serif;
  }
  
  .password-input {
    position: relative;
  }
  
  .password-input input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Titillium Web", sans-serif;
  }
  
  .password-toggle-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #666;
  }
  
  .response-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .submit-button {
    font-weight: 600;
    line-height: 125%;
    text-align: center;
    font-size: 1rem;
    letter-spacing: 1.44px;
    background: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
    background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
    color: #f5f3f0;
    padding: 0.9rem 1.875rem;
    border: 1px solid rgba(244, 242, 240, .1);
    box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, .3);
    border-radius: 3.75rem;
    font-family: "Titillium Web", sans-serif;
    margin-top: 10px;
  }
  
  