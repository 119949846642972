/* Forensics Container */
.forensics-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px; /* Add padding for spacing */
  border-radius: 8px; /* Rounded corners */
  max-width: 600px; /* Max width for container */
  margin: 50px auto; /* Centered with margin */
}

/* Forensics Content */
.forensics-content {
  text-align: center; /* Center text */
}

.forensics-content h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333; /* Darker text for readability */
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
}

.forensics-content p {
margin-bottom: 20px;
color: #555; /* Subtle color for the paragraph */
font-size: 1rem;
font-family: "Titillium Web", sans-serif;
}

.forensics-modal-actions {
  margin-top: 30px;
}

.forensics-modal-actions button {
  color: white;
    padding: 10px 20px;
    text-decoration: none;
    font-family: "Titillium Web", sans-serif;
    color: #672024;
    font-weight: 600;
    background: -webkit-linear-gradient(top, #f9f8f7 0%, #ddd7d2 100%);
    background: linear-gradient(180deg, #f9f8f7 0%, #ddd7d2 100%);
    border: 1px solid rgba(244, 242, 240, .1);
    background-color: var(--btn-bg-color);
    border-radius: 3.75rem;
    margin-right: 20px;
    cursor: pointer;
    font-size: 1rem;
}