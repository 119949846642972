.folder-file-manager-main {
  padding: 20px;
  font-family: Arial, sans-serif;
  min-height: calc( 100vh - 190px );
}

.folder-file-manager-main h2 {
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
}

.folder-list-container,
.file-manager-container {
  margin-top: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
}

.folder-input,
.file-upload {
  display: flex;
  gap: 10px;
}

.folder-input input,
.file-upload input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.folders,
.file-list {
  margin-top: 20px;
}

.folder-item,
.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0);
  border-bottom: 1px solid #ddd;
}

.folder-icon {
  color: #f0a500;
  margin-right: 10px;
  font-size: 24px;
}

.pdf-icon {
  color: #d32f2f;
  margin-right: 10px;
  font-size: 24px;
}

.folder-item span,
.file-item span {
  flex-grow: 1;
  cursor: pointer;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
}

.folder-file-manager-main button,
.folder-item button,
.file-item button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.folder-item button:hover,
.file-item button:hover {
  background-color: #e0e0e0;
}

.rename-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.folder-modal-content {
  background: white;
  padding: 20px 40px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 400;
  gap: 10px;
}

.folder-modal-content input {
  padding: 8px;
  width: 100%;
  margin-bottom: 10px;
}

.folder-modal-content button {
  padding: 8px 12px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.folder-modal-content button:hover {
  background-color: #e0e0e0;
}

/* General mobile optimization */
@media (max-width: 768px) {
.folder-file-manager-main {
  padding: 10px;
  font-size: 14px;
  min-height: calc(100vh - 150px); /* Adjust height for smaller screens */
}

.folder-file-manager-main h2 {
  font-size: 20px;
  text-align: center;
}

.folder-list-container,
.file-manager-container {
  margin-top: 10px;
  padding: 15px;
}

.folder-input,
.file-upload {
  flex-direction: column; /* Stack inputs vertically */
  gap: 5px;
}

.folder-input input,
.file-upload input {
  padding: 8px;
  font-size: 12px;
  width: 100%;
}

.folders,
.file-list {
  margin-top: 15px;
}

.folder-item,
.file-item {
  flex-direction: column; /* Stack file details vertically */
  align-items: flex-start;
  padding: 8px;
}

.folder-icon,
.pdf-icon {
  font-size: 20px;
}

.folder-item span,
.file-item span {
  font-size: 14px;
  margin-bottom: 5px;
}

.folder-file-manager-main button,
.folder-item button,
.file-item button {
  padding: 5px 8px;
  font-size: 12px;
  width: 100%; /* Full width buttons for easy tapping */
  margin: 5px 0;
}

/* Modal responsiveness */
.rename-modal {
  align-items: flex-start;
  justify-content: center;
  padding-top: 20px;
}

.folder-modal-content {
  padding: 15px 20px;
  max-width: 90%;
  gap: 8px;
}

.folder-modal-content input {
  font-size: 14px;
  padding: 8px;
}

.folder-modal-content button {
  padding: 6px 10px;
  font-size: 12px;
}
}

/* For very small screens like phones (max-width: 480px) */
@media (max-width: 480px) {
.folder-file-manager-main h2 {
  font-size: 18px;
}

.folder-item,
.file-item {
  padding: 6px;
}

.folder-item span,
.file-item span {
  font-size: 12px;
}

.folder-icon,
.pdf-icon {
  font-size: 18px;
}

.folder-file-manager-main button,
.folder-item button,
.file-item button {
  font-size: 11px;
  padding: 4px 6px;
}
}

.folder-input {
  display: flex;
  justify-content: flex-end;
}

.folder-input button {
  background: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
  background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
    color: #FFF;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-family: "Titillium Web", sans-serif;
}
