.pricing-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 0rem;
}

.total-credits {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 50px;
  font-size: 16px;
  color: #333;
}

.total-credits p {
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.5rem;
  color: #080f17;
  line-height: 2.25rem;
}

.total-credits span {
  color: #672024; /* You can choose a color that fits your design */
  font-weight: 600;
}

.pricing-content {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-items: center;
}

.pricing-header {
  text-align: center;
  margin: 50px 30%;
  margin-top: 10px;
}

.pricing-title {
  font-size: 3.5rem;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 110%;
  color: #080f17;
  letter-spacing: -1.6px;
  font-family: "Titillium Web", sans-serif;
  margin-top: 10px;
}

.pricing-title span {
  color: #672024;
}

.pricing-description {
  font-family: "Titillium Web", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.5rem;
  color: #080f17;
  line-height: 2.25rem;
  margin-bottom: 20px;
}

.pricing-cards {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center; /* Center items within the grid */
  justify-content: center; /* Center the grid itself */
  width: 100%; /* Ensure it takes full width of parent */
  padding: 0 200px;
  text-align: center;
}

.pricing-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0px solid #e5e7eb;
  border-radius: 0rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  width: 340px; /* Fixed width */
  height: 325px; /* Fixed height */
  background-color: -webkit-linear-gradient(73deg, #030507 12.05%, #113e33 88.83%);
  background: linear-gradient(17deg, #030507 12.05%, #113e3c 88.83%);
  box-shadow: 8px 8px 24px 10px rgba(0, 0, 0, .3);
  margin-bottom: 30px;
}
 
.pricing-card:nth-child(1) {
  --card-bg-color: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
  background: linear-gradient(135deg, #11293e 0%, #030507 100%);
}

.pricing-card:nth-child(2) {
  --card-bg-color: -webkit-linear-gradient(73deg, #030507 12.05%, #113e33 88.83%);
  background: linear-gradient(17deg, #030507 12.05%, #113e3c 88.83%);
}

.pricing-card:nth-child(3) {
  --card-bg-color: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
  background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
}


.card-content {
  padding: 1.5rem;
}

.card-title {
  font-size: 2rem;
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  color: #fff;
}

.card-description {
  margin-top: 0.5rem;
  color: #e3e3e3;
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
}


.card-price {
  font-size: 1.5rem;
  color: #e3e3e3;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 30px;
  font-family: "Titillium Web", sans-serif;
}


.how-it-works {
  text-align: center;
  background: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
background: linear-gradient(17deg, #030507 12.05%, #11293e 88.83%);
  padding: 100px;
  margin: 40px 0;
}

.section-title {
  font-size: 3rem;
  margin-bottom: 50px;
  font-weight: 400;
  line-height: 110%;
  color: #080f17;
  letter-spacing: -1.6px;
  font-family: "Titillium Web", sans-serif;
}

.section-title-works {
  font-size: 3rem;
  margin-bottom: 50px;
  font-weight: 300;
  line-height: 110%;
  color: #fff;
  letter-spacing: -1.6px;
  font-family: "Titillium Web", sans-serif;
}

.section-title span {
    color: #672024;
}

.steps {
  display: flex;
  gap: 3rem;
  justify-content: center;
  flex-wrap: wrap;
}

.step {
  flex: 1;
  max-width: 300px;
  text-align: left;
}

.step-icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.5rem;
}

.step-title {
  font-family: "Titillium Web", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1.5rem;
  color: #f3f1f0;
  letter-spacing: 1.7px;
  margin-bottom: 1rem;
  text-transform: uppercase;
  line-height: 2.5075rem;
  margin-bottom: 20px;
}

.step-description {
  font-weight: 400;
  color: #ededed;
  font-style: normal;
  font-size: 1.125rem;
  line-height: 1.8rem;
  font-family: "Titillium Web", sans-serif;
}

.faq {
  text-align: center;
  padding: 50px;
  background-color: #f5f3f0;
}

.accordion {
  max-width: 800px;
  margin: 0 auto;
}

.accordion-item {
  margin-bottom: 1rem;
}

.accordion-trigger {
  display: block;
  width: 100%;
  text-align: left;
  border: 0px solid #fff;
  background-color: #f5f3f0;
  padding: 1rem;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.8rem;
  font-family: "Titillium Web", sans-serif;
  cursor: pointer;
}

.accordion-content {
  padding: 1rem;
  border-bottom: 1px solid #d7d7d7;
  background-color: #f5f3f0;
  font-size: 1rem;
  line-height: 1.8rem;
  font-family: "Titillium Web", sans-serif;
  text-align: left;
}

.billing-history {
  text-align: center;
  padding: 50px 20%;
  min-height: calc(100vh - 350px);
}

.billing-history-table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Titillium Web", sans-serif;
  margin-bottom: 50px;
}

.billing-history-table th, .billing-history-table td {
  border-bottom: 5px solid #fff;
  padding: 15px;
}

.billing-history-table th {
  background-color: #fff;
  text-align: left;
  font-weight: 600;
}

.billing-history-table tr {
  background-color: #f5f3f0;
  text-align: left;
}

/* Subscription Details Section */
.subscription-details {
  background-color: #f7f7f7;
  border: 1px solid #ffffff;
  padding: 50px 100px;
  border-radius: 10px;
  max-width: 700px;
  margin: 0 auto 40px;
  text-align: center;
}

.subscription-details h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 110%;
  color: #080f17;
  letter-spacing: -1.6px;
  font-family: "Titillium Web", sans-serif;
}

.subscription-details p {
  color: #333;
  margin-bottom: 10px;
  font-size: 1.2rem;
  line-height: 1.75rem;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
}

.subscription-details span {
  color: #672024;
}

.manage-subscription-btn {
  font-weight: 600;
  line-height: 125%;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 1.44px;
  background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
  color: #f5f3f0;
  padding: 1.03125rem 1.875rem;
  border: 1px solid rgba(244, 242, 240, .1);
  box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, .3);
  border-radius: 3.75rem;
  font-family: "Titillium Web", sans-serif;
  margin-top: 20px;
  cursor: pointer;
}

/* pricing-tabs container */
.pricing-tabs-container {
display: flex;
justify-content: center;
margin-bottom: 10px;
border-bottom: 1px solid #ccc;
margin-top: -100px;
}

/* Tab buttons */
.pricing-tabs-container button {
background: #fff;
border: none;
padding: 15px 40px;
margin: 10px 5px;
margin-bottom: 0;
cursor: pointer;
font-size: 16px;
color: #333;
border-radius: 0px;
border-bottom: 0px solid #672024;
transition: background-color 0.3s ease, color 0.3s ease;
}

/* Active tab button */
.pricing-tabs-container button.active-tab {
background: #fff;
border-bottom: 2px solid #672024;
color: #333;
}

/* Active tab button */
.pricing-tabs-container button.active-tab p {
color: #777;
}

/* Active tab button */
.pricing-tabs-container button.active-tab p span {
color: #672024;
}

/* Hover effect */
.pricing-tabs-container button:hover {
background: #fff; /* Primary blue color */
border-bottom: 2px solid #672024;
color: #333;
}

@media (max-width: 768px) {
  .pricing-container {
    padding: 0 0rem;
    gap: 2rem;
  }

  .total-credits {
    margin-bottom: 20px;
    font-size: 14px;
    padding: 8px;
  }

  .total-credits p {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .pricing-content {
    gap: 2rem;
  }

  .pricing-header {
    margin: 20px auto;
    margin-top: 10px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .pricing-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  .pricing-description {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
    .section-title, .section-title-works {
      font-size: 2rem;
      margin-bottom: 30px;
    }
    .pricing-tabs-container {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      border-bottom: 1px solid #ccc;
      margin-top: -50px;
    }

    .billing-history {
      text-align: center;
      padding: 50px 10%;
      min-height: calc(100vh - 350px);
    }
}

@media (max-width: 480px) {
  .total-credits {
    font-size: 12px;
    padding: 6px;
  }

  .billing-history {
    text-align: center;
    padding: 50px 10%;
    min-height: calc(100vh - 350px);
  }
  
  .total-credits p {
    font-size: 1rem;
    line-height: 1.6rem;
  }

  .pricing-title {
    font-size: 2rem;
  }

  .pricing-description {
    font-size: 1rem;
  }

    .section-title, .section-title-works {
      font-size: 2rem;
      margin-bottom: 30px;
    }

    .step {
      max-width: 100%;
      text-align: center;
    }
  
    .step-icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  
    .step-title {
      font-size: 1.5rem;
    }
  
    .step-description {
      font-size: 1rem;
    }
  
}


.date-filter {
display: flex;
flex-wrap: wrap;
gap: 1rem;
margin-bottom: 1.5rem;
padding: 1rem;
background-color: var(--background);
border-radius: 0.5rem;
box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.date-filter-group {
flex: 1;
min-width: 200px;
}

.date-filter-label {
display: block;
font-size: 0.875rem;
font-weight: 500;
color: var(--foreground);
margin-bottom: 0.5rem;
}

.date-filter-input {
width: 100%;
padding: 0.5rem;
font-size: 0.875rem;
line-height: 1.25rem;
border: 1px solid var(--border);
border-radius: 0.375rem;
background-color: var(--background);
color: var(--foreground);
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.date-filter-input:focus {
outline: none;
border-color: var(--primary);
box-shadow: 0 0 0 1px var(--primary);
}

.date-filter-button {
padding: 0.5rem 1rem;
font-size: 0.875rem;
font-weight: 500;
color: var(--primary-foreground);
background-color: var(--primary);
border: none;
border-radius: 0.375rem;
cursor: pointer;
transition: background-color 0.15s ease-in-out;
}

.date-filter-button:hover {
background-color: var(--primary-hover);
}

.date-filter-button:focus {
outline: none;
box-shadow: 0 0 0 2px var(--background), 0 0 0 4px var(--primary);
}

@media (max-width: 640px) {
.date-filter {
  flex-direction: column;
}

.date-filter-group {
  width: 100%;
}

.date-filter-button {
  width: 100%;
  margin-top: 1rem;
}
}

