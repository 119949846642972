/* Background overlay */
.m1odal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it sits above other content */
  }
  
  /* Modal content container */
  .m1odal-content {
    background: #fff;
    padding: 0px;
    border-radius: 8px;
    width: 80%;
    max-width: 95%;
    height: 90%;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Close button styling */
  .c1lose-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: #888;
  }
  