/* General Styles */
.rd-body {
  font-family: 'Titillium Web', sans-serif;
  background-color: #f5f5f5;
  color: #333;
}

.rd-dashboard-container {
  min-height: 100vh;
  padding: 50px 100px;
}

.rd-nav-bar {
  margin-bottom: 2rem;
}

.rd-nav-text {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #333;
}

.rd-job-roles-header {
  display: flex;
  justify-content: space-between;
}

.rd-job-roles-header button {
  background: -webkit-linear-gradient(73deg, #030507 12.05%, #11293e 88.83%);
  background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
    color: #FFF;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-family: "Titillium Web", sans-serif;
    height: fit-content;
    letter-spacing: 1px;
}

.rd-job-roles-header span {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  color: #410F12;
}

/* Card Styles */
.rd-job-roles-card {
  border-radius: 0.5rem;
  padding: 1.5rem 0;
  margin-bottom: 1.5rem;
}

.rd-candidate-card,
.rd-activity-card,
.rd-upgrade-card {
  background-color: #fff;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.rd-job-roles-title,
.rd-activity-title,
.rd-upgrade-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.rd-job-roles-content,
.rd-activity-content {
  font-size: 0.875rem;
}

/* KPI Cards */
.rd-kpi-cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.rd-kpi-card {
  color: #fff;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.rd-kpi-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
}

.rd-kpi-card-red {
  background: linear-gradient(180deg, #672024 0%, #410F12 100%);
}

.rd-kpi-card-teal {
  background: linear-gradient(180deg, #1E4B4F 0%, #0C141D 100%);
}

.rd-kpi-card-dark {
  background: linear-gradient(16.82deg, #030507 12.05%, #11293E 88.83%);
}

.rd-kpi-card-title {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 2;
}

.rd-kpi-card-content {
  position: relative;
  z-index: 2;
}

/* Main Content Layout */
.rd-main-content-grid {
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 1.5rem;
}

/* Job Roles Table Styles */
.rd-job-roles-table {
  width: 100%;
  
  border-collapse: separate;
  border-spacing: 0 5px;
}

.rd-job-roles-table th,
.rd-job-roles-table td {
  padding: 15px;
  text-align: left;
  border: none;
}

.rd-job-roles-table th {
  width: 24%;
  background-color: #f4f4f4;
  font-weight: bold;
  color: #333;
}

.rd-job-roles-table tr {
  background-color: #ffffff;
}

/* Table Styles */
/*This section was already present in the original code. No changes needed*/


/* Button Styles */
.rd-btn {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.rd-btn-secondary {
  background-color: #e2e8f0;
  color: #4a5568;
}

.rd-btn-secondary:hover {
  background-color: #cbd5e0;
}

.rd-btn-link {
  background: none;
  border: none;
  color: #672024;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.rd-btn-link:hover {
  color: #8b2b30;
}

.rd-btn-icon {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  background-color: #fff;
  border-radius: 0.25rem;
}

.rd-btn-full {
  width: 100%;
}

.action-icon {
  cursor: pointer;
  color: #555;
  transition: color 0.3s ease;
}

.action-icon:hover {
  color: #672024;
}

/* Candidate Cards */
.rd-candidates-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.rd-candidate-card {
  padding: 1.5rem;
}

.rd-candidate-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.rd-candidate-score {
  display: inline-block;
  background-color: #5E1C20;
  color: #fff;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.rd-candidate-name {
  font-weight: 600;
}

.rd-candidate-stats {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rd-stat-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
}

.rd-progress-bar {
  background-color: #e2e8f0;
  border-radius: 9999px;
  height: 0.5rem;
  overflow: hidden;
}

.rd-progress {
  background-color: #5E1C20;
  height: 100%;
}

/* Sidebar */
.rd-sidebar {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.rd-activity-item {
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #e2e8f0;
  padding-top: 0.9rem;
}

.rd-activity-item:last-child {
  border-bottom: none;
}

.rd-activity-text {
  margin-bottom: 0.25rem;
}

.rd-activity-time {
  font-size: 0.75rem;
  color: #718096;
}

.rd-upgrade-card {
  background-color: #1AA2AE;
  color: #fff;
}

/* Edit Popup Styles */
.edit-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.edit-popup {
  background: #ffffff;
  padding: 40px;
  width: 1000px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1010;
  font-family: "Titillium Web", sans-serif;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}

.job-details p {
  margin-bottom: 10px;
}

.job-details strong {
  font-weight: 600;
}

/* Search Container Styles */
.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.search-container input {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
}

/* Pagination Styles */
.rd-navigation-arrows {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.rd-navigation-arrows button {
  background: none;
  border: 1px solid #e2e8f0;
  padding: 0.5rem;
  margin: 0 0.25rem;
  cursor: pointer;
}

.rd-navigation-arrows button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.rd-navigation-arrows span {
  margin: 0 0.5rem;
}

/* Navigation Arrows */
/*This section was already present in the original code. No changes needed*/

/* Icons */
.rd-icon {
  width: 0.6rem;
  height: 0.6rem;
}

.rd-table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .rd-kpi-cards-grid,
  .rd-main-content-grid,
  .rd-candidates-grid {
    grid-template-columns: 1fr;
    padding-right: 100px;
  }

  .rd-table-wrapper {
    margin: 0 -1rem;
    padding: 0 1rem;
  }

  .rd-job-roles-table {
    font-size: 12px;
  }

  .rd-job-roles-table th,
  .rd-job-roles-table td {
    padding: 8px 5px;
  }
  .rd-btn {
    padding: 0.375rem 0.75rem;
    font-size: 0.75rem;
  }
  .rd-btn-link,
  .action-icon {
    font-size: 12px;
  }
  .rd-sidebar {
    margin-top: 1.5rem;
    padding-right: 100px;
  }
  .rd-dashboard-container {
    padding: 20px;
    overflow-x: hidden;
  }

  .rd-main-content-grid {
    grid-template-columns: 1fr;
  }

  .rd-kpi-cards-grid {
    grid-template-columns: 1fr;
  }

  .rd-kpi-card {
    padding: 1rem;
  }

  .rd-kpi-card-title {
    font-size: 1.5rem;
  }

  .rd-job-roles-card,
  .rd-activity-card {
    padding: 1rem;
  }

  .rd-job-roles-header {
    flex-direction: column;
    align-items: flex-start;
    padding-right: 50px;
  }

  .rd-job-roles-header button {
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
  }

  .rd-job-roles-table {
    font-size: 0.8rem;
  }

  .rd-job-roles-table th,
  .rd-job-roles-table td {
    padding: 0.5rem;
  }

  .rd-btn-link {
    font-size: 0.8rem;
  }

  .rd-sidebar {
    margin-top: 2rem;
  }

  .rd-activity-item {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  .rd-activity-text {
    font-size: 0.9rem;
  }

  .rd-activity-time {
    font-size: 0.7rem;
  }

  .edit-popup {
    padding: 20px;
    width: 95%;
  }

  .job-form2 {
    flex-direction: column;
  }

  .form-column {
    width: 100%;
  }

  .save-btn {
    width: 100%;
    margin-top: 1rem;
  }
}

/* Accessibility */
.rd-btn:focus,
.rd-btn-link:focus {
  outline: 2px solid #4299e1;
  outline-offset: 2px;
}

.rd-progress-bar {
  position: relative;
}

.rd-progress-bar::before {
  content: attr(aria-valuenow) '%';
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;
}

/* Print Styles */
@media print {
  .rd-dashboard-container {
    padding: 0;
  }

  .rd-btn,
  .rd-navigation-arrows {
    display: none;
  }

  .rd-job-roles-table {
    page-break-inside: avoid;
  }

  .rd-candidate-card {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

/* New Styles */
.job-form2 {
  padding: 20px;
  padding-top: 0;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  padding-bottom: 50px;
}

.form-column {
  width: 48%;
  display: flex;
  flex-direction: column;
}

.form-column label {
  margin-top: 10px;
  font-weight: 500;
}

.form-column input,
.form-column textarea,
.form-column select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f5f3f0;
  font-family: "Titillium Web", sans-serif;
}

.form-column textarea {
  min-height: 125px;
  font-size: 1rem;
}

.options-popup-dropdown {
  width: 100%;
  padding: 10px;
  background-color: #f5f3f0;
  font-size: 16px;
  border: 0px solid #555;
  border-radius: 5px;
  font-family: "Titillium Web", sans-serif;
}

.skills-container {
  margin-bottom: 20px;
}

.skills-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.skill-box {
  background-color: #f5f3f0;
  border-radius: 8px;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  font-family: "Titillium Web", sans-serif;
  font-size: 14px;
}

.delete-skill-btn {
  background: none;
  border: none;
  color: #333;
  margin-left: 5px;
  cursor: pointer;
}

.save-btn {
  font-weight: 600;
  line-height: 125%;
  text-align: center;
  font-size: 1rem;
  letter-spacing: 1.44px;
  background: linear-gradient(180deg, #5b161a 0%, #3e0d10 100%);
  color: #f5f3f0;
  padding: 0.93125rem 2.175rem;
  border: 1px solid rgba(244, 242, 240, .1);
  box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, .3);
  border-radius: 3.75rem;
  font-family: "Titillium Web", sans-serif;
  margin-top: 30px;
  cursor: pointer;
}

.rd-table-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Print Styles */
@media print {
  .rd-dashboard-container {
    padding: 0;
  }

  .rd-btn,
  .rd-navigation-arrows {
    display: none;
  }

  .rd-job-roles-table {
    page-break-inside: avoid;
  }

  .rd-candidate-card {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .rd-kpi-cards-grid,
  .rd-main-content-grid,
  .rd-candidates-grid {
    grid-template-columns: 1fr;
  }

  .rd-job-roles-table {
    font-size: 14px;
  }

  .rd-job-roles-table th,
  .rd-job-roles-table td {
    padding: 10px;
  }
  .rd-btn {
    padding: 0.375rem 0.75rem;
    font-size: 0.75rem;
  }
  .rd-btn-link,
  .action-icon {
    font-size: 12px;
  }
  .rd-sidebar {
    margin-top: 1.5rem;
  }
  .rd-dashboard-container {
    padding: 20px;
  }

  .rd-main-content-grid {
    grid-template-columns: 1fr;
  }

  .rd-kpi-cards-grid {
    grid-template-columns: 1fr;
  }

  .rd-kpi-card {
    padding: 1rem;
  }

  .rd-kpi-card-title {
    font-size: 1.5rem;
  }

  .rd-job-roles-card,
  .rd-activity-card {
    padding: 1rem;
  }

  .rd-job-roles-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .rd-job-roles-header button {
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
  }

  .rd-job-roles-table {
    font-size: 0.8rem;
  }

  .rd-job-roles-table th,
  .rd-job-roles-table td {
    padding: 0.5rem;
  }

  .rd-btn-link {
    font-size: 0.8rem;
  }

  .rd-sidebar {
    margin-top: 2rem;
  }

  .rd-activity-item {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  .rd-activity-text {
    font-size: 0.9rem;
  }

  .rd-activity-time {
    font-size: 0.7rem;
  }

  .edit-popup {
    padding: 20px;
    width: 95%;
  }

  .job-form2 {
    flex-direction: column;
  }

  .form-column {
    width: 100%;
  }

  .save-btn {
    width: 100%;
    margin-top: 1rem;
  }
}